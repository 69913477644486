<template>
  <div>
    <router-view/>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  components: {
  },
  data () {
    return {
    }
  },
  mounted () {
  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
</style>
